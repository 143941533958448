import {FormattedMessage} from "react-intl";
import {HomeCategoryVariantProps} from "./HomeCategory";

export default function HomeCategoryDesktop(props: HomeCategoryVariantProps) {

	return (
		<div className={"tw-mt-14"}>
			<div
				className={"tw-text-sliderSubTitle tw-font-bold tw-font-bold tw-font-gotham-black tw-text-black tw-mb-6"}>
				<FormattedMessage id={'frontend.home.category.title'}/>
			</div>
			<div
				className={"tw-flex tw-flex-row tw-text-black tw-font-productDetailDescription tw-justify-between"}>
				{props.categories && props.categories.map((category, index) =>
					<a href={`${category.url}`}
					   key={category.title}
					   className={"tw-flex tw-flex-col tw-p-2 tw-items-center"}>
						<div className={'tw-relative'}>
							<img className={'tw-w-homeCategoryWidth tw-h-homeCategoryHeight'} src={category.imgUrl} alt={category.title}
								 onMouseOver={e => (e.currentTarget.src = category.hoverImgUrl)}
								 onMouseOut={e => (e.currentTarget.src = category.imgUrl)}/>
						</div>
						<div className={"tw-mt-4 tw-text-center"}>
							{category.title}
						</div>

					</a>
				)}

			</div>
		</div>
	);

}